import React from 'react'
import './index.css'
import { FormattedMessage, useIntl } from 'react-intl'

const Header = () => {
    const { locale } = useIntl()

    return (
        <div>
            <header className='header'>
                <div className='header-content'>
                    <div>
                        {locale === 'ru' && (
                            <a href='/'>
                                <img src='/assets/images/scanslatet-logo-min.png' alt='Scanslated Logo' className='logo' />
                            </a>
                        )}
                    </div>
                    <nav className={`nav`}>
                        <a href='/app/example/'>
                            <button className='sample-report-button'>
                                <FormattedMessage id={'hero.button.try'} />
                            </button>
                        </a>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header
