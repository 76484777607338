import React, { useEffect, useRef, useState } from 'react'

// Styles
import './index.css'

// Components
import Header from '../../components/Sections/Header'
import { FormattedMessage } from 'react-intl'
// import Carousel from '../../components/Sections/Carousel'
// import CustomersSerction from '../../components/Sections/Customers'

const Index = ({ locale }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const reportRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (reportRef.current) {
                const rect = reportRef.current.getBoundingClientRect()
                const isVisible = rect.top + (window.innerHeight - 200) < window.innerHeight && rect.bottom >= 0
                setIsModalVisible(isVisible)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className='app'>
            <Header />
            <main className='main-content'>
                <section className='hero'>
                    <h1>
                        <FormattedMessage id='hero.title' />
                    </h1>
                    <div className={'report-sample-button-container'}>
                        <a href='/app/example/'>
                            <button className='sample-report-button big'>
                                <FormattedMessage id='hero.button.try' />
                            </button>
                        </a>
                        <div ref={reportRef} className='report-container'>
                            <img src={'/assets/images/report-image.png'} alt='Пример отчета' className='report-image' />
                            <img
                                src={'/assets/images/hint-modal.png'}
                                alt='Пример модалки'
                                className={`term-modal-image ${isModalVisible && 'visible'}`}
                            />
                        </div>
                    </div>
                </section>

                <section className='features'>
                    <h1>
                        <FormattedMessage id='features.title' />
                    </h1>
                </section>

                <section className='how-it-works'>
                    <div className='how-it-works-content'>
                        <div className='how-it-works-image'>
                            <img src={`/assets/images/how-it-works-image-${locale}.png`} alt='Пример радиологического отчета' />
                        </div>
                        <div className='how-it-works-text'>
                            <h2>
                                <FormattedMessage id='howItWorks.title' />
                            </h2>
                            <h3>
                                <FormattedMessage id='howItWorks.subtitle' />
                            </h3>
                            <p>
                                <FormattedMessage id='howItWorks.text' />
                            </p>
                            <p>
                                <FormattedMessage id='howItWorks.patients' />
                            </p>
                            <a href='/app/example/'>
                                <button className='sample-report-button'>
                                    <FormattedMessage id='howItWorks.button.viewSample' />
                                </button>
                            </a>
                        </div>
                    </div>
                </section>

                <section className='benefits-for-health-systems'>
                    <div className='benefits-content'>
                        <div className='benefits-image'>
                            <img src={`/assets/images/how-it-works-image-${locale}.png`} alt='Пример радиологического отчета' />
                        </div>
                        <div className='benefits-text'>
                            <h2>
                                <FormattedMessage id='benefits.title' />
                            </h2>
                            <h3>
                                <FormattedMessage id='benefits.subtitle' />
                            </h3>
                            <p>
                                <FormattedMessage id='benefits.text' />
                            </p>
                            <a href='/app/example/'>
                                <button className='book-demo-button'>
                                    <FormattedMessage id='howItWorks.button.viewSample' />
                                </button>
                            </a>
                        </div>
                    </div>
                </section>

                <footer className='footer'>
                    <h2 className='footer-title'>
                        <FormattedMessage id='footer.title' />
                    </h2>
                    <a href='mailto:info@scanslated.ru' className='footer-email'>
                        <FormattedMessage id='footer.email' />
                    </a>
                    {locale === 'ru' && (
                        <div className='footer-info'>
                            <p>
                                ПО распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не
                                требуются.
                            </p>
                            <p>
                                Стоимость определяется индивидуально. Обратитесь по адресу электронной почты{' '}
                                <a href='mailto:info@scanslated.ru'>info@scanslated.ru</a>.
                            </p>
                            <p>
                                <a href='/documentation'>Документация</a>
                            </p>
                        </div>
                    )}
                    <div className='footer-bottom'>
                        <p className='footer-copyright'>
                            <FormattedMessage id='footer.copyright' />
                        </p>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default Index
