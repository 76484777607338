import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useIntl } from 'react-intl'
import HomePage from './routes/HomePage'
import Documentation from './routes/Documentation'
import './App.css'

function App({ locale }) {
    const intl = useIntl()
    React.useEffect(() => {
        document.title = intl.formatMessage({ id: 'site-title' })
    }, [intl])

    return (
        <div>
            <Routes>
                <Route path={'/'} Component={() => <HomePage locale={locale} />} />
                <Route path={'/documentation'} Component={() => <Documentation locale={locale} />} />
            </Routes>
        </div>
    )
}

export default App
